<template>
  <div v-if="smsTemplate">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('sms.smsTemplateUpdate')"
      :title="this.$helper.getEnumTranslation('sms_template_name', smsTemplate.name, this.$i18n.locale)"
      @submitPressed="submitPressed"
    />
    <div class="content-box">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group
              class="row"
              :label="$t('sms.enabled')"
              label-for="h-enabled"
              label-cols-md="2"
              content-cols-lg="4"
            >
              <b-form-checkbox v-model="smsTemplate.onOff" id="h-enabled" switch/>
            </b-form-group>
            <b-form-group :label="$t('sms.message')" label-for="sms-message">
              <b-form-textarea v-model="smsTemplate.message" name="sms-message" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import useVuelidate from "@vuelidate/core";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";

export default {
  components: {
    TitleBar
  },
  mixins: [ResourceUtils, ListUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      smsTemplate: {
        message: {required: this.$translateError('required')},
      },
    };
  },
  data() {
    return {
      smsTemplate: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$SmsTemplates.getResource({id}).then((response) => {
        this.smsTemplate = response.data;
      });
    },
    async submitPressed() {
      const isValid = await this.v$.$validate();
      this.$emit("clearAction");
      if (isValid) {
        const body = JSON.parse(JSON.stringify(this.smsTemplate));
        this.update(this.$SmsTemplates, body.id, body, this.$t("sms.smsTemplateUpdated"), null, null, this.error);
      }
    },
  },
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
